<template>
  <div class="page-classroom-view">
    <div class="my-page">
      <van-form @submit="save" style="background-color: transparent;">
        <div class="my-card">
          <van-field
            readonly
            required
            v-model="viewData.CreateUserName"
            name="CreateUserName"
            label="请假人"
            placeholder="请假人"
            :rules="[{ required: true, message: '请选择请假人' }]"
          />
          <van-field
            readonly
            required
            v-model="viewData.LeaveTypeName"
            name="LeaveTypeName"
            label="请假类型"
            placeholder="请假类型"
            :rules="[{ required: true, message: '请选择请假类型' }]"
          />
          <van-popup v-model="showPicker" position="bottom">
            <van-picker
              show-toolbar
              value-key="EnumName"
              :columns="leaveTypeList"
              @confirm="onConfirm"
              @cancel="showPicker = false"
            />
          </van-popup>
          <van-field
            readonly
            required
            v-model="viewData.FlowIDName"
            name="FlowIDName"
            label="审核流程"
            placeholder="审核流程"
            :rules="[{ required: true, message: '请选择审核流程' }]"
          />
          <van-popup v-model="showPicker2" position="bottom">
            <van-picker
              show-toolbar
              value-key="Name"
              :columns="auditFlowList"
              @confirm="onConfirm2"
              @cancel="showPicker2 = false"
            />
          </van-popup>
          <van-field
            readonly
            required
            v-model="viewData.BeginDate"
            name="BeginDate"
            label="请假开始时间"
            placeholder="请假开始时间"
            :rules="[{ required: true, message: '请选择请假开始时间' }]"
          />
          <van-popup v-model="showTime" position="bottom">
            <van-datetime-picker
              type="datetime"
              title="请选择日期"
              :min-date="minDate"
              @confirm="onConfirmTime"
              @cancel="showTime = false"
            />
          </van-popup>
          <van-field
            readonly
            required
            v-model="viewData.EndDate"
            name="EndDate"
            label="请假结束时间"
            placeholder="请假结束时间"
            :rules="[{ required: true, message: '请选择请假结束时间' }]"
          />
          <van-popup v-model="showTime2" position="bottom">
            <van-datetime-picker
              type="datetime"
              title="请选择日期"
              :min-date="minDate"
              @confirm="onConfirmTime2"
              @cancel="showTime2 = false"
            />
          </van-popup>
          <van-field
            readonly
            required
            type="number"
            v-model="viewData.LeaveDays"
            name="LeaveDays"
            label="请假天数"
            placeholder="请假天数"
            :rules="[
              { required: true, message: '请填写请假天数' },
              {
                pattern: /^(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*))$/,
                message: '请填写正数'
              }
            ]"
          />
          <van-field
            readonly
            required
            type="number"
            v-model="viewData.LeaveTimeCount"
            name="LeaveTimeCount"
            label="请假总时长"
            placeholder="请假总时长"
            :rules="[
              { required: true, message: '请填写请假总时长' },
              {
                pattern: /^(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*))$/,
                message: '请填写正数'
              }
            ]"
          />
          <van-field
            readonly
            required
            v-model="viewData.LeaveDesc"
            name="LeaveDesc"
            rows="2"
            label="请假事由"
            type="textarea"
            placeholder="请输入请假事由"
            :rules="[{ required: true, message: '请输入请假事由' }]"
          />
          <van-field name="AccessoryPostList" label="附件">
            <template #input>
              <van-uploader
                readonly
                v-model="viewData.AccessoryPostList"
                :deletable="false"
                :preview-full-image="false"
                accept="*"
                :before-read="beforeRead"
                @click-preview="review"
              />
            </template>
          </van-field>
        </div>

        <!-- <van-button
          :loading="loading"
          block
          color="#ffe500"
          type="info"
          native-type="submit"
          >提交</van-button
        > -->
      </van-form>
      <div class="my-card">
        <van-collapse v-model="activeNames">
          <van-collapse-item title="审核流程" name="1">
            <van-steps
              direction="vertical"
              :active="flowList.length > 0 ? flowList.length - 1 : 0"
              active-color="#ffe500"
            >
              <van-step v-for="(item, i) in flowList" :key="i">
                <div class="flow-item">
                  <div class="flow-top">
                    <van-tag
                      v-if="
                        item.AuditStateName === '未审核' ||
                          item.AuditStateName === '驳回'
                      "
                      color="#f5222d"
                      type="primary"
                      size="large"
                      plain
                    >
                      {{ item.AuditStateName }}
                    </van-tag>
                    <van-tag
                      v-if="item.AuditStateName === '通过'"
                      color="#1890ff"
                      type="primary"
                      size="large"
                      plain
                    >
                      {{ item.AuditStateName }}
                    </van-tag>
                    <span class="name">{{ item.AuditUserName }}</span>
                    <span class="time">{{ item.AuditDateStr }}</span>
                  </div>
                  <div class="flow-bottom">审核意见：{{ item.AuditDesc }}</div>
                </div>
              </van-step>
            </van-steps>
          </van-collapse-item>
        </van-collapse>
      </div>
      <!-- 按钮组 -->
      <div class="btn-box" v-if="showAudit">
        <van-button
          color="#ffe500"
          type="info"
          style="width: 48%"
          @click="handlePass"
          >通过</van-button
        >
        <van-button
          type="warning"
          style="width: 48%"
          @click="dialogVisible = true"
          >驳回</van-button
        >
      </div>
      <!-- 驳回弹窗 -->
      <van-dialog
        v-model="dialogVisible"
        title="驳回"
        :beforeClose="beforeClose"
        showCancelButton
      >
        <van-field
          required
          v-model="AuditDesc"
          name="LeaveDesc"
          rows="2"
          label="驳回原因"
          type="textarea"
          placeholder="请输入驳回原因"
        />
      </van-dialog>
    </div>
  </div>
</template>

<script>
import {
  Button,
  Form,
  Field,
  Uploader,
  Popup,
  Picker,
  DatetimePicker,
  Collapse,
  CollapseItem,
  Step,
  Steps,
  Tag,
  Dialog,
  Toast
} from 'vant'
export default {
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [Uploader.name]: Uploader,
    [Button.name]: Button,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [DatetimePicker.name]: DatetimePicker,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Step.name]: Step,
    [Steps.name]: Steps,
    [Tag.name]: Tag,
    [Dialog.Component.name]: Dialog.Component,
    [Toast.name]: Toast
  },
  data () {
    return {
      minDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      id: null,
      viewData: {
        AccessoryPostList: []
      },
      flowList: [],
      leaveTypeList: [],
      auditFlowList: [],
      showPicker: false,
      showPicker2: false,
      showTime: false,
      showTime2: false,
      loading: false,
      activeNames: ['1'],
      dialogVisible: false,
      AuditDesc: '',
      showAudit:false
    }
  },
  mounted () {
    if (this.$route.query.id) {
      this.id = this.$route.query.id
      this.AuditID = this.$route.query.AuditID
      this.getDetail()
      this.getEnum()
      // 请求审核流程
      this.getFlow()
    }
  },
  methods: {
    getEnum () {
      this.$axios.get('/api/Base/GetEnum?type=LeaveType').then(res => {
        if (res.code === 200) {
          this.leaveTypeList = res.data.List
        }
      })
      this.$axios.get('/api/AuditFlow/List?limit=99').then(res => {
        if (res.code === 200) {
          this.auditFlowList = res.data
        }
      })
    },
    getDetail () {
      this.$axios
        .get('/api/WorkerLeave/Get', {
          ID: this.id
        })
        .then(res => {
          if (res.code === 200) {
            if(res.data.LeaveState == 3 || res.data.LeaveState == 4){
              this.showAudit = false;
            }else{
              this.showAudit = true;
            }
            this.viewData = {
              ...res.data,
              AccessoryPostList: res.data.accessoryList
            }
          } else {
            this.$toast.fail(res.msg || '请求异常')
            return false
          }
        })
    },
    getFlow () {
      // 请求审核流程
      this.$axios
        .get('/api/Auidt/AuditInfo', {
          ID: this.id
        })
        .then(res => {
          if (res.code === 200) {
            this.flowList = res.data
          }
        })
    },
    onConfirm (value) {
      this.viewData.LeaveTypeName = value.EnumName
      this.viewData.LeaveType = value.EnumValue
      this.showPicker = false
    },
    onConfirm2 (value) {
      this.viewData.FlowIDName = value.Name
      this.viewData.FlowID = value.ID
      this.showPicker2 = false
    },
    onConfirmTime (value) {
      this.viewData.BeginDate = new Date(value).format('yyyy-MM-dd hh:mm')
      this.showTime = false
      // 计算总时长
      this.sumTime()
    },
    onConfirmTime2 (value) {
      this.viewData.EndDate = new Date(value).format('yyyy-MM-dd hh:mm')
      this.showTime2 = false
      // 计算总时长
      this.sumTime()
    },
    sumTime () {
      const startTime = this.viewData.BeginDate
      const endTime = this.viewData.EndDate
      if (startTime && endTime) {
        this.$set(
          this.viewData,
          'LeaveTimeCount',
          this.$moment(endTime)
            .diff(this.$moment(startTime), 'hours', true)
            .toFixed(1)
        )
      }
    },
    beforeRead (file) {
      Toast.loading({
        message: '加载中...',
        duration: 0
      })
      let formData = new FormData()
      formData.append('LastFile', file)
      formData.append('FileName', file.name)
      this.$axios.upload('/api/File/UploadFile', formData).then(res => {
        Toast.clear()
        if (res.code === 200) {
          this.viewData.AccessoryPostList.push({
            name: file.name,
            osskey: res.data.osskey,
            uploadUrl: res.data.uploadUrl,
            url: res.data.url
          })
          return true
        } else {
          this.$toast.fail(res.msg || '操作失败')
          return false
        }
      })
    },
    review (file) {
      if (file.url) {
        window.open(file.url)
      }
    },
    save () {
      let that = this
      this.$axios.post('/api/WorkerLeave/Add', this.viewData).then(res => {
        if (res.code === 200) {
          this.$toast.success(res.msg || '操作成功')
          setTimeout(() => {
            that.$router.replace({
              path: 'LeaveTeacher',
              query: {
                active: 0
              }
            })
          }, 1000)
        } else {
          this.$toast.fail(res.msg || '操作失败')
        }
      })
    },
    handlePass () {
      let that = this
      this.$axios
        .post('/api/Auidt/Audit', {
          AuditDesc: '通过',
          AuditID: this.AuditID,
          AuditState: 3
        })
        .then(res => {
          if (res.code === 200) {
            this.$toast.success(res.msg || '操作成功')
            setTimeout(() => {
              that.$router.replace({
                path: 'LeaveTeacher',
                query: {
                  active: 1
                }
              })
            }, 1000)
          } else {
            this.$toast.fail(res.msg || '操作失败')
          }
        })
    },
    beforeClose (action, done) {
      let that = this
      if (action === 'confirm') {
        if (!this.AuditDesc) {
          this.$toast.fail('请填写驳回原因！')
          done(false)
        } else {
          this.$axios
            .post('/api/Auidt/Audit', {
              AuditDesc: this.AuditDesc,
              AuditID: this.AuditID,
              AuditState: 4
            })
            .then(res => {
              if (res.code === 200) {
                this.$toast.success(res.msg || '操作成功')
                this.AuditDesc = ''
                done()
                setTimeout(() => {
                  that.$router.replace({
                    path: 'LeaveTeacher',
                    query: {
                      active: 1
                    }
                  })
                }, 1000)
              } else {
                this.$toast.fail(res.msg || '操作失败')
              }
            })
        }
      } else {
        this.AuditDesc = ''
        done()
      }
    }
  }
}
</script>
<style lang="less" scoped>
@import './index.less';
</style>
